$(document).ready(() => {
  initCardsSlider();
  initMenu();
  initFilters();
  initPriceSlider();
  initCollapseText();
  initPhotoGallery();
  initForms();
  initModals();
});

function initCardsSlider() {
  $.each($('.cards-slider'), (k, v) => {
    const slider = v.querySelector('.cards-slider__main');
    const btnPrev = v.querySelector('.cards-slider__prev');
    const btnNext = v.querySelector('.cards-slider__next');
    const pagination = v.querySelector('.cards-slider__pagination');
    const slidesPevViewDesktop = parseInt(v.getAttribute('data-slides-desktop') || 4);

    new Swiper(slider, {
      loop: true,
      pagination: {
        el: pagination,
      },
      slidesPerView: 1,
      navigation: {
        nextEl: btnNext,
        prevEl: btnPrev,
      },
      breakpoints: {
        1200: {
          slidesPerView: slidesPevViewDesktop,
        },
        768: {
          slidesPerView: 2,
        },
      },
    });
  });
}

function initMenu() {
  $('.js-open-menu').click(function(e) {
    e.preventDefault();
    $('html').addClass('menu-active');
  });

  $('.js-close-menu').click(function(e) {
    e.preventDefault();
    $('html').removeClass('menu-active');
  });
}

function initFilters() {
  $('.js-filter-section-toggle').click(function(e) {
    e.preventDefault();
    $(this).closest('.filter__section').toggleClass('is-active');
  });

  $('.js-filter-open').click(function(e) {
    e.preventDefault();
    $('html').addClass('filter-active');
  });

  $('.js-filter-close').click(function(e) {
    e.preventDefault();
    $('html').removeClass('filter-active');
  });
}

function initPriceSlider() {
  const slider = $('.interval-slider');
  if (slider.length) {
    const min = parseInt(slider.attr('data-min'));
    const minStart = parseInt(slider.attr('data-start-min'));
    const max = parseInt(slider.attr('data-max'));
    const maxStart = parseInt(slider.attr('data-start-max'));
    const valuesLabels = [
  
    ];
    const minLabel = $('.interval-slider-min');
    const maxLabel = $('.interval-slider-max');
  
    noUiSlider.create(slider[0], {
      range: {
        'min': min,
        'max': max,
      },
      start: [minStart, maxStart],
      step: 1,
      connect: true,
    });
  
    slider[0].noUiSlider.on('update', function (values, handle) {
      minLabel.val(parseInt(values[0]));
      maxLabel.val(parseInt(values[1]));
    });
  }
}

function initCollapseText() {
  $('.collapse-text__btn').click(function(e) {
    e.preventDefault();
    $(this).closest('.collapse-text').addClass('all-visible');
  });
}

function initPhotoGallery() {
  if ($('.photo-gallery').length) {
    const photoGalleryNav = new Swiper('.photo-galley__nav-slider', {
      direction: "vertical",
      slidesPerView: 2,
      navigation: {
        nextEl: '.photo-gallery__nav-next',
        prevEl: '.photo-gallery__nav-prev',
      },
      spaceBetween: 10,
      freeMode: true,
      watchSlidesProgress: true,
    });

    const photoGalleryMain = new Swiper('.photo-galley__main-slider', {
      slidesPerView: 1,
      pagination: {
        el: '.photo-gallery__main-pagination',
      },
      thumbs: {
        swiper: photoGalleryNav,
      },
    });
  }
}

function initForms() {
  $('.js-form-submit').click(function(e) {
    e.preventDefault();
    $(this).closest('.form').addClass('show-res');
  });
}

function initModals() {
  $('.js-modal-close').click(function(e) {
    e.preventDefault();
    $(this).closest('.modal').removeClass('is-active');
    $('html').removeClass('modal-active');
  });

  $('.js-modal-open').click(function(e) {
    e.preventDefault();
    const modal = $(this).attr('data-modal');
    $(modal).addClass('is-active');
    $('html').addClass('modal-active');
  });

  $(document).keyup(function(e) {
    if (e.key === "Escape") {
      $('.modal.is-active').removeClass('is-active');
      $('html').removeClass('modal-active');
    }
  });
}